.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding: 1em;
  margin-bottom: 2em;
  background-color: rgb(22, 163, 71);
  color: #fff;
}

.App-link {
  color: #61dafb;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1em;
}

.nav-link-button {
  padding: 1em;
  border-style: none;
  color: #fff;
  background-color: rgb(22, 163, 71);
}

.form-check-input:checked {
  background-color: rgb(22, 163, 71);
  border-color: rgb(22, 163, 71);
}

.table-title {

}

.table-scrollable{
  height: 400px;
  padding: 0 10px 10px 10px;
  overflow-y: scroll;
}

Table thead {
  background-color: #fff;
  position: sticky;
  top: 0;
  margin: 0 0 0 0;
}

.page-title {

}